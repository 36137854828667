import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KhoaService } from 'src/app/Utils/service/khoa.service';
import { LoaiDanhGiaService } from 'src/app/Utils/service/loai-danh-gia.service';
import { PhanPhoiCanBoService } from 'src/app/Utils/service/phan-phoi-can-bo.service';
import { PhieuDanhGiaGuiCanBoService } from 'src/app/Utils/service/phieu-danh-gia-gui-can-bo.service';
import { PhieuDanhGiaService } from 'src/app/Utils/service/phieu-danh-gia.service';
import { PhongBanService } from 'src/app/Utils/service/phong-ban.service';
import { SearchObjectService } from 'src/app/Utils/service/search-object.service';
import { SeasonService } from 'src/app/Utils/service/season.service';
import { common } from 'src/app/app.common';


@Component({
  selector: 'app-khao-sat-can-bo',
  templateUrl: './khao-sat-can-bo.component.html',
  styleUrls: ['./khao-sat-can-bo.component.scss']
})
export class KhaoSatCanBoComponent implements OnInit {

  Token: any;
  UserInfo: any;
  dotDanhGia: any;
  loaiDanhGia: any;
  phieuDanhGia: any
  phieuDanhGiaFilter: any
  boMon: any
  khoa: any;
  phieuDanhGiaDaGui: any;
  phieuDanhGiaMotCanBo: any;
  listGuiPhieuDanhGia: any
  lstPhongBan: any
  phanPhoiCanBo: any
  public com: common
  phanPhoiTinChiService: any;
  ChonCount: any = 0;
  closeResult: string;

  Fillter = new FormGroup({
    Ho_ten: new FormControl(null),
    Ma_can_bo: new FormControl(null),
    ID_phong_ban: new FormControl(0),
    ID_khoa: new FormControl(0),
    ID_dot_danh_gia: new FormControl(0),
    ID_phieu_danh_gia: new FormControl(0),
    ID_loai_danh_gia: new FormControl(-1)
  })
  constructor(
    public router: Router,
    private dotDanhGiaSv: SeasonService,
    private loaiDanhGiaSv: LoaiDanhGiaService,
    private phieuDanhGiaSv: PhieuDanhGiaService,
    private khoaSv: KhoaService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private phongBanService: PhongBanService,
    private phanPhoiCanBoSV: PhanPhoiCanBoService,
    private phieuDanhGiaGuiCanBoSV: PhieuDanhGiaGuiCanBoService,
    private searchObjectService: SearchObjectService
  ) { }


  async ngOnInit(): Promise<void> {
    // get UserData
    this.com = new common(this.router);
    this.com.CheckLogin();
    var saveData = this.com.getUserinfo();
    this.UserInfo = saveData.Info.UserName;
    this.Token = saveData.access_token;

    await this.getDotDanhGia();
    this.getLoaiDanhGia();
    this.getPhieuDanhGia();
    this.getKhoa();
    this.getPhongBan()
    this.getPhanPhoiCanBo()


  }
  getDotDanhGia(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dotDanhGiaSv.getListSeason(this.Token).subscribe(res => {
        this.dotDanhGia = res.Data;
        this.Fillter.patchValue({
          ID_dot_danh_gia: this.dotDanhGia.length > 0 ? this.dotDanhGia[0].ID_dot_danh_gia : 0
        })

        resolve(); // Gọi resolve() khi hàm hoàn thành
      }, error => {
        reject(error); // Gọi reject() nếu có lỗi xảy ra
      });
    });
  }
  getLoaiDanhGia() {
    this.loaiDanhGiaSv.getAll(this.Token).subscribe(res => {
      if (res.Status == 1) {
        this.loaiDanhGia = res.ListData;
      }
    })
  }
  getPhieuDanhGia() {

    this.phieuDanhGiaSv.getPhieuDanhGia(this.Token, this.Fillter.value.ID_loai_danh_gia).subscribe(res => {
      this.phieuDanhGia = res.ListData
      this.phieuDanhGiaFilter = res.ListData

    });

  }
  filterPhieuDanhGia() {
    this.phieuDanhGiaFilter = this.phieuDanhGia.filter((obj) => {
      return (
        obj.ID_loai_danh_gia == this.Fillter.value.ID_loai_danh_gia ||
        this.Fillter.value.ID_loai_danh_gia == -1
      );
    });

  }



  getKhoa() {
    this.khoaSv.getKhoa(this.Token).subscribe(res => {
      this.khoa = res.ListData

    })
  }

  getPhongBan() {
    this.phongBanService.getAll(this.Token).subscribe((z) => {
      this.lstPhongBan = z.sort((a, b) => (a.Phong > b.Phong ? 1 : -1));
    });
  }

  getPhanPhoiCanBo() {
    var data = this.Fillter.value;
    this.spinner.show();
    this.phanPhoiCanBoSV
      .getPhanPhoiCanBo(this.Token, data)
      .subscribe((z) => {
        this.spinner.hide();
        this.phanPhoiCanBo = z.Data;
        this.ChonCount = 0;

      });
  }



  insertDotDanhGiaGui(data) {
    this.spinner.show();
    this.phieuDanhGiaGuiCanBoSV.insertDotDanhGiaGui(data, this.Token).subscribe(res => {
      this.spinner.hide();

      this.getPhanPhoiCanBo();
      if (res.Status == 1) {
        this.toastr.success(res.Message);
      } else {
        this.toastr.warning(res.Message);
      }

    })
  }


  checkOne(index) {
    this.phanPhoiCanBo[index].Chon = !this.phanPhoiCanBo[index].Chon;
    this.ChonCount = this.phanPhoiCanBo.filter((obj) => {
      return obj.Chon == true;
    }).length;

  }

  checkAll() {
    var value = false;
    var ChonCount = this.phanPhoiCanBo.filter((obj) => {
      return obj.Chon == true
    }).length;

    if (ChonCount < this.phanPhoiCanBo.length) {
      value = true
    }
    for (let i = 0; i < this.phanPhoiCanBo.length; i++) {
      this.phanPhoiCanBo[i].Chon = value
    }
    this.ChonCount = this.phanPhoiCanBo.filter((obj) => {
      return obj.Chon == true
    }).length

  }

  open(content, phieuDanhGiaGui) {
    if (phieuDanhGiaGui.ID_dot_danh_gia == null) {
      this.toastr.warning("Không có dữ liệu");
    } else {
      // Gán giá trị cho phieuDanhGiaMotCanBo
      this.phieuDanhGiaMotCanBo = {
        ID_dot_danh_gia: phieuDanhGiaGui.ID_dot_danh_gia,
        Ten_dot: '',
        Ngay_bat_dau: new Date(phieuDanhGiaGui.Ngay_bat_dau).toLocaleDateString('vi-VN'),
        Ngay_ket_thuc: new Date(phieuDanhGiaGui.Ngay_ket_thuc).toLocaleDateString('vi-VN'),
        Phieu_danh_gia: phieuDanhGiaGui.lstPhieu
      };
  
      // Tìm tên đợt đánh giá
      for (let i = 0; i < this.dotDanhGia.length; i++) {
        if (this.dotDanhGia[i].ID_dot_danh_gia == phieuDanhGiaGui.ID_dot_danh_gia) {
          this.phieuDanhGiaMotCanBo.Ten_dot = this.dotDanhGia[i].Ten_dot;
        }
      }
  
      // Mở modal
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }  

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  guiPhieu() {
    var r = confirm("Bạn có muốn gửi không?");
    if (r) {
      this.guiPhieuDanhGia()
    }
  }

  guiPhieuDanhGia() {

    this.listGuiPhieuDanhGia = []
    var ChonCount = this.phanPhoiCanBo.filter((obj) => {
      return obj.Chon == true
    }).length;
    if (this.Fillter.value.ID_dot_danh_gia == 0 || this.Fillter.value.ID_phieu_danh_gia == 0 || ChonCount == 0) {
      this.toastr.warning("Chọn đọt đánh giá, phiếu đánh giá và cán bộ cần gửi");
    } else {
      var data = {
        ID_dot_danh_gia: this.Fillter.value.ID_dot_danh_gia,
        ID_phieu_danh_gia: this.Fillter.value.ID_phieu_danh_gia,
        listUser: []
      }
      for (let i = 0; i < this.phanPhoiCanBo.length; i++) {
        if (this.phanPhoiCanBo[i].Chon == true) {
          data.listUser.push({
            UserID: this.phanPhoiCanBo[i].UserID,
            UserName: this.phanPhoiCanBo[i].UserName
          });
      
          this.listGuiPhieuDanhGia = data;
        }
      }

      this.insertDotDanhGiaGui(this.listGuiPhieuDanhGia);

      for (let i = 0; i < this.phanPhoiCanBo.length; i++) {
        this.phanPhoiCanBo[i].Chon = false
      }
      this.ChonCount = 0
    }
  }

  deleteMulti(data: any) {
    if(data == undefined || data == null || data.length == 0){
      this.toastr.warning("Không có dữ liệu");
      return;
    }
    else{
      let listID: number[] = data.map(item => item.ID_gui);
      var r = confirm("Bạn có muốn xóa phiếu đã được gửi đến danh sách lớp đã chọn không?")
      if (!r) {
        return;
      }
      this.spinner.show();
      this.searchObjectService.DeleteMulti(listID, this.Token).subscribe((z) => {
        this.spinner.hide();
        this.toastr.success(z.Message);
        this.getPhanPhoiCanBo();
      });
    }
  }
}
