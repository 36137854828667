import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoaiDanhGiaService } from 'src/app/Utils/service/loai-danh-gia.service';
import { PhanPhoiDoanhNghiepService } from 'src/app/Utils/service/phan-phoi-doanh-nghiep.service';
import { PhieuDanhGiaGuiDoanhNghiepService } from 'src/app/Utils/service/phieu-danh-gia-gui-doanh-nghiep.service';
import { PhieuDanhGiaService } from 'src/app/Utils/service/phieu-danh-gia.service';
import { SearchObjectService } from 'src/app/Utils/service/search-object.service';
import { SeasonService } from 'src/app/Utils/service/season.service';
import { common } from 'src/app/app.common';

@Component({
  selector: 'app-khao-sat-doanh-nghiep',
  templateUrl: './khao-sat-doanh-nghiep.component.html',
  styleUrls: ['./khao-sat-doanh-nghiep.component.scss']
})
export class KhaoSatDoanhNghiepComponent implements OnInit {

  Token: any;
  UserInfo: any;
  dotDanhGia: any;
  loaiDanhGia: any;
  phieuDanhGia: any
  phieuDanhGiaFilter: any
  public com: common
  phanPhoiDoanhNghiep: any
  ChonCount: any = 0;
  phieuDanhGiaDaGui: any
  phieuDanhGiaMotDoanhNghiep: any
  closeResult: string;
  listGuiPhieuDanhGia: any

  Fillter = new FormGroup({
    Ten_doanh_nghiep: new FormControl(null),
    Ma_so_thue: new FormControl(null),
    Tim_kiem_nhanh: new FormControl(null),
    ID_dot_danh_gia: new FormControl(0),
    ID_phieu_danh_gia: new FormControl(0),
    ID_loai_danh_gia: new FormControl(-1)
  })

  constructor(
    public router: Router,
    private dotDanhGiaSv: SeasonService,
    private loaiDanhGiaSv: LoaiDanhGiaService,
    private phieuDanhGiaSv: PhieuDanhGiaService,
    public spinner: NgxSpinnerService,
    public phanPhoiDoanhNghiepSV: PhanPhoiDoanhNghiepService,
    public phieuDanhGiaGuiDoanhNghiepSV: PhieuDanhGiaGuiDoanhNghiepService,
    private searchObjectService: SearchObjectService,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) { }

  async ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var saveData = this.com.getUserinfo();
    this.UserInfo = saveData.Info.UserName;
    this.Token = saveData.access_token;

    await this.getDotDanhGia();
    this.getLoaiDanhGia();
    this.getPhieuDanhGia();
    this.getPhanPhoiDoanhNghiep();
  }


  getDotDanhGia(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dotDanhGiaSv.getListSeason(this.Token).subscribe(res => {
        this.dotDanhGia = res.Data;
        this.Fillter.patchValue({
          ID_dot_danh_gia: this.dotDanhGia.length > 0 ? this.dotDanhGia[0].ID_dot_danh_gia : 0
        })

        resolve(); // Gọi resolve() khi hàm hoàn thành
      }, error => {
        reject(error); // Gọi reject() nếu có lỗi xảy ra
      });
    });
  }
  getLoaiDanhGia() {
    this.loaiDanhGiaSv.getAll(this.Token).subscribe(res => {
      if (res.Status == 1) {
        this.loaiDanhGia = res.ListData;
      }

    })
  }
  getPhieuDanhGia() {

    this.phieuDanhGiaSv.getPhieuDanhGia(this.Token, this.Fillter.value.ID_loai_danh_gia).subscribe(res => {
      this.phieuDanhGia = res.ListData
      this.phieuDanhGiaFilter = res.ListData

    });


  }
  filterPhieuDanhGia() {
    this.phieuDanhGiaFilter = this.phieuDanhGia.filter((obj) => {
      return (
        obj.ID_loai_danh_gia == this.Fillter.value.ID_loai_danh_gia ||
        this.Fillter.value.ID_loai_danh_gia == -1
      );
    });

  }

  getPhanPhoiDoanhNghiep() {
    var data = this.Fillter.value;
    this.spinner.show();
    this.phanPhoiDoanhNghiepSV
      .getPhanPhoiDoanhNghiep(this.Token, data)
      .subscribe((z) => {
        this.spinner.hide();
        this.phanPhoiDoanhNghiep = z.Data;
        this.ChonCount = 0;

      });
  }

  


  checkOne(index) {
    this.phanPhoiDoanhNghiep[index].Chon = !this.phanPhoiDoanhNghiep[index].Chon;
    this.ChonCount = this.phanPhoiDoanhNghiep.filter((obj) => {
      return obj.Chon == true;
    }).length;

  }

  checkAll() {
    var value = false;
    var ChonCount = this.phanPhoiDoanhNghiep.filter((obj) => {
      return obj.Chon == true
    }).length;

    if (ChonCount < this.phanPhoiDoanhNghiep.length) {
      value = true
    }
    for (let i = 0; i < this.phanPhoiDoanhNghiep.length; i++) {
      this.phanPhoiDoanhNghiep[i].Chon = value
    }
    this.ChonCount = this.phanPhoiDoanhNghiep.filter((obj) => {
      return obj.Chon == true
    }).length

  }

  open(content, phieuDanhGiaGui) {
    if (phieuDanhGiaGui.ID_dot_danh_gia == null) {
      this.toastr.warning("Không có dữ liệu");
    } else {
      // Gán giá trị cho phieuDanhGiaMotDoanhNghiep
      this.phieuDanhGiaMotDoanhNghiep = {
        ID_dot_danh_gia: phieuDanhGiaGui.ID_dot_danh_gia,
        Ten_dot: '',
        Ngay_bat_dau: new Date(phieuDanhGiaGui.Ngay_bat_dau).toLocaleDateString('vi-VN'),
        Ngay_ket_thuc: new Date(phieuDanhGiaGui.Ngay_ket_thuc).toLocaleDateString('vi-VN'),
        Phieu_danh_gia: phieuDanhGiaGui.lstPhieu
      };
  
      // Tìm tên đợt đánh giá
      for (let i = 0; i < this.dotDanhGia.length; i++) {
        if (this.dotDanhGia[i].ID_dot_danh_gia == phieuDanhGiaGui.ID_dot_danh_gia) {
          this.phieuDanhGiaMotDoanhNghiep.Ten_dot = this.dotDanhGia[i].Ten_dot;
        }
      }
  
      // Mở modal
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  insertDotDanhGiaGui(data) {
    this.spinner.show();
    this.phieuDanhGiaGuiDoanhNghiepSV.insertDotDanhGiaGui(data, this.Token).subscribe(res => {
      this.spinner.hide();

      this.getPhanPhoiDoanhNghiep();
      if (res.Status == 1) {
        this.toastr.success(res.Message);
      } else {
        this.toastr.warning(res.Message);
      }

    })
  }

  guiPhieu() {
    var r = confirm("Bạn có muốn gửi không?");
    if (r) {
      this.guiPhieuDanhGia()
    }
  }

  guiPhieuDanhGia() {

    this.listGuiPhieuDanhGia = []
    var ChonCount = this.phanPhoiDoanhNghiep.filter((obj) => {
      return obj.Chon == true
    }).length;
    if (this.Fillter.value.ID_dot_danh_gia == 0 || this.Fillter.value.ID_phieu_danh_gia == 0 || ChonCount == 0) {
      this.toastr.warning("Chọn đọt đánh giá, phiếu đánh giá và doanh nghiệp cần gửi");
    } else {
      var data = {
        ID_dot_danh_gia: this.Fillter.value.ID_dot_danh_gia,
        ID_phieu_danh_gia: this.Fillter.value.ID_phieu_danh_gia,
        ID_doanh_nghiep: []
      }
      for (let i = 0; i < this.phanPhoiDoanhNghiep.length; i++) {
        if (this.phanPhoiDoanhNghiep[i].Chon == true) {
          data.ID_doanh_nghiep.push(this.phanPhoiDoanhNghiep[i].ID_doanh_nghiep)


          this.listGuiPhieuDanhGia = data
        }
      }

      this.insertDotDanhGiaGui(this.listGuiPhieuDanhGia);

      for (let i = 0; i < this.phanPhoiDoanhNghiep.length; i++) {
        this.phanPhoiDoanhNghiep[i].Chon = false
      }
      this.ChonCount = 0
    }
  }

  deleteMulti(data: any) {
    if(data == undefined || data == null || data.length == 0){
      this.toastr.warning("Không có dữ liệu");
      return;
    }
    else{
      let listID: number[] = data.map(item => item.ID_gui);
      var r = confirm("Bạn có muốn xóa phiếu đã được gửi đến danh sách lớp đã chọn không?")
      if (!r) {
        return;
      }
      this.spinner.show();
      this.searchObjectService.DeleteMulti(listID, this.Token).subscribe((z) => {
        this.spinner.hide();
        this.toastr.success(z.Message);
        this.getPhanPhoiDoanhNghiep();
      });
    }
  }
}
